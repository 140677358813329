type MenuItemId =
  | 'calendar'
  | 'schemats'
  | 'surveys'
  | 'patients'
  | 'forms'
  | 'notifications'
  | 'employees'
  | 'medicines'
  | 'symptoms'
  | 'reports'
  | 'costs-estimate'
  | 'other-reports';

export const MENU: {
  id: MenuItemId;
  icon: string;
  title: string;
  path: string;
  submenu: { id: MenuItemId; title: string; path: string }[];
}[] = [
  {
    id: 'calendar',
    icon: 'pi pi-calendar',
    title: 'Kalendarz',
    path: '/calendar/week',
    submenu: [],
  },
  {
    id: 'schemats',
    icon: 'pi pi-server',
    title: 'Schematy',
    path: '/schemats',
    submenu: [],
  },
  {
    id: 'surveys',
    icon: 'pi pi-heart',
    title: 'Badania kliniczne',
    path: '/surveys',
    submenu: [],
  },
  {
    id: 'patients',
    icon: 'pi pi-users',
    title: 'Pacjenci',
    path: '/patients',
    submenu: [],
  },
  {
    id: 'forms',
    icon: 'pi pi-check-square',
    title: 'Formularze',
    path: '/forms',
    submenu: [],
  },
  {
    id: 'notifications',
    icon: 'pi pi-bell',
    title: 'Powiadomienia',
    path: '/notifications',
    submenu: [],
  },
  {
    id: 'employees',
    icon: 'pi pi-briefcase',
    title: 'Personel',
    path: '/employees',
    submenu: [],
  },
  {
    id: 'medicines',
    icon: 'pi pi-link',
    title: 'Leki',
    path: '/medicines',
    submenu: [],
  },
  {
    id: 'symptoms',
    icon: 'pi pi-receipt',
    title: 'Objawy',
    path: '/symptoms',
    submenu: [],
  },
  {
    id: 'reports',
    icon: 'pi pi-folder',
    title: 'Raporty',
    path: '/reports',
    submenu: [
      {
        id: 'costs-estimate',
        title: 'Kosztorys',
        path: '/reports/costs-estimate',
      },
      {
        id: 'other-reports',
        title: 'Inne raporty',
        path: '/reports/other-reports',
      },
    ],
  },
];

export const STATUS_OPTIONS = [
  {
    label: 'Do zaplanowania',
    value: 'to_plan',
    class: '!bg-gray-100 !border-gray-200 [&__.p-select-label]:!text-gray-700',
  },
  // {
  //   label: 'Zaplanowana',
  //   value: 'planned',
  //   class:
  //     '!bg-success-50 !border-success-200 [&__.p-select-label]:!text-success-700',
  // },
  {
    label: 'Niepotwierdzona',
    value: 'not_confirmed',
    class:
      '!bg-warning-50 !border-warning-200 [&__.p-select-label]:!text-warning-700',
  },
  {
    label: 'Potwierdzona',
    value: 'confirmed',
    class:
      '!bg-success-50 !border-success-200 [&__.p-select-label]:!text-success-700',
  },
  {
    label: 'Odrzucona',
    value: 'rejected',
    class:
      '!bg-error-50 !border-error-200 [&__.p-select-label]:!text-error-700',
  },
  {
    label: 'Zrealizowana',
    value: 'completed',
    class:
      '!bg-success-50 !border-success-200 [&__.p-select-label]:!text-success-700',
  },
];
